import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  Cart, LoyaltyOfferOperation, useApplyLoyaltyOfferMutation, YumCart
} from '@pizza-hut-us-development/client-core';
import phdApiAccountManagementService from '@/services/accountManagementService/phdApiAccountManagementService';
import logger from '@/common/logger';
import telemetry from '@/telemetry';
import { redeemLoyaltyDeal, setDealToDisplay } from '@/builders/deals/slice/userSelections.slice';
import Routes from '@/router/routes';
import { RewardsMessage } from '@/rewards/t3Iframe/T3Iframe.types';
import { showErrorModal } from '@/common/Modal';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { openLocalizationRail, openModal } from '@/localization/actions';
import { actions as profileActions } from '@/account/profile/profile.slice';
import { onAccountRewardsClickRedeem } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { RootState } from '@/rootStateTypes';
import { isRedemptionWarning } from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/helpers';
import useCodeRedemption from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/hooks/useCodeRedemption';

export interface RedeemLoyaltyRewards {
  redeem(coupon: RewardsMessage['coupon']['key'] | string, event?: MessageEvent): void;
  getInProgressLoyaltyCodes: (cart: Cart) => string[];
}

const useRedeemLoyaltyRewards = (): RedeemLoyaltyRewards => {
  const userIsLocalized = useSelector(localizationSelectors.isLocalized);
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const dispatch = useDispatch();
  const router = useRouter();
  const analytics = useAnalytics();

  const [handleRedemptionWarnings] = useCodeRedemption();

  const [applyLoyaltyOffer] = useApplyLoyaltyOfferMutation();

  const getInProgressLoyaltyCodes = (cart: Cart) => {
    // TODO: Remove typecast when CC type is updated
    const appliedCodes: string[] = (cart as YumCart).appliedPromotions?.reduce((acc: string[], promotion) => {
      const loyaltyOfferId = promotion.loyaltyOfferIds?.[0];
      if (loyaltyOfferId && !acc.includes(loyaltyOfferId)) return [...acc, loyaltyOfferId];
      return acc;
    }, []) || [];

    // TODO: Remove typecast when CC type is updated
    const codesInWarnings: string[] = (cart as YumCart).warnings?.reduce((acc: string[], warning) => {
      if (!isRedemptionWarning(warning)) return acc;
      const loyaltyOfferId = warning.loyaltyOffer?.loyaltyOfferId;
      if (loyaltyOfferId && !acc.includes(loyaltyOfferId)) return [...acc, loyaltyOfferId];
      return acc;
    }, []) || [];

    return [...appliedCodes, ...codesInWarnings];
  };

  const redeem = async (redemptionCode: number | string, event?: MessageEvent) => {
    analytics.push(() => onAccountRewardsClickRedeem());

    if (isYumEcomm && typeof redemptionCode === 'string') {
      const addLoyaltyItemToCart = async () => {
        const loyaltyResponse = await applyLoyaltyOffer({
          loyaltyOfferId: redemptionCode,
          operation: LoyaltyOfferOperation.APPLY
        });
        // Failed call
        if ('error' in loyaltyResponse) {
          dispatch(openModal({
            title: 'We\'re sorry',
            body: 'Something went wrong. Please try again.',
            cta: {
              text: 'OK'
            }
          }));
          logger.withoutTelemetry.error(`Loyalty item ${redemptionCode} failed to add to cart`);
          return;
        }

        handleRedemptionWarnings(redemptionCode, loyaltyResponse.data, undefined, 'loyalty');
        if (!event) return;
        // Post message to iframe with any in progress loyalty items
        event.source?.postMessage({ cartItems: getInProgressLoyaltyCodes(loyaltyResponse.data) }, { targetOrigin: event.origin });
      };

      if (!userIsLocalized) {
        dispatch(openLocalizationRail({
          handleAfterLocalizing: () => {
            addLoyaltyItemToCart();
          }
        }));
      } else {
        addLoyaltyItemToCart();
      }
      return;
    }

    const {
      result: loyalty,
      error: serviceError
    } = await phdApiAccountManagementService.myAccount.customerLoyalty();

    if (loyalty?.coupons) {
      dispatch(profileActions.updateCoupons(loyalty?.coupons));
    }

    if (serviceError) {
      showErrorModal(dispatch);
    }

    const reward = loyalty?.coupons?.find((c) => c.rewardsRedemptionCode === redemptionCode);
    if (!reward) {
      const error = new Error('No loyalty coupon matching redemption code');
      logger.withoutTelemetry.error(String(error), { redemptionCode });
      telemetry.addNoticeError(error, { redemptionCode });
      showErrorModal(dispatch);
      return;
    }

    if (!userIsLocalized) {
      dispatch(openLocalizationRail({
        handleAfterLocalizing: () => {
          dispatch(setDealToDisplay({
            dealId: reward.code,
            rewardsRedemptionCode: reward.rewardsRedemptionCode
          }));
          dispatch(redeemLoyaltyDeal(true));
          router.push({ pathname: Routes.DEALS, query: { asPath: 'reward' } });
        }
      }));
    } else {
      dispatch(setDealToDisplay({
        dealId: reward.code,
        rewardsRedemptionCode: reward.rewardsRedemptionCode
      }));
      dispatch(redeemLoyaltyDeal(true));
      await router.push({ pathname: Routes.DEALS, query: { asPath: 'reward' } });
    }
  };

  return {
    redeem,
    getInProgressLoyaltyCodes
  };
};

export default useRedeemLoyaltyRewards;
