import React, {
  MutableRefObject, useRef, useState, useEffect, useCallback
} from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { useDispatch, useSelector } from 'react-redux';
import getConfig from 'next/config';

import { YumCart } from '@pizza-hut-us-development/client-core';
import { useStyles } from './T3Iframe.styles';
import useRewardsListener from './hooks/useRewardsListener';
import { LoyaltyState } from './constants';
import { LoyaltyToggle } from './T3Iframe.types';
import { selectors } from '@/account/profile/profile.slice';
import useCustomerDetails from '@/account/profile/hooks/useCustomerDetails';
import useRedeemLoyaltyRewards from '@/rewards/t3Iframe/hooks/useRedeemLoyaltyReward';
import { onHutRewardsOptOut } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import standaloneApiClient from '@/api/standaloneApiClient';
import StandaloneEndpoints from '@/api/standaloneApiClient/endpoints';
import { openCartRail } from '@/cart/actions';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';

const { publicRuntimeConfig } = getConfig();

const rewardsUrl = (
  loyaltyToken: string,
  useOptOut = false,
  urlParams: T3IFrameProps
): URL => {
  const optOutURL = `${publicRuntimeConfig.REWARDS_IFRAME_URL}/opt-out-confirmation`;
  const urlToUse = useOptOut
    ? optOutURL
    : publicRuntimeConfig.REWARDS_IFRAME_URL;
  const url = new URL(urlToUse);
  url.searchParams.set('token', loyaltyToken);
  Object.keys(urlParams).forEach((paramKey) => {
    const urlParamVal = urlParams[paramKey as keyof T3IFrameProps];
    if (urlParamVal) {
      url.searchParams.set(paramKey, urlParamVal);
    }
  });
  return url;
};

type T3IFrameProps = {
  pr?: string;
  co?: string;
  flogin?: string;
  fregister?: string;
  loggedin?: string;
  store_loyalty?: string;
  yum_token?: string;
  sc_list?: string;
  rewardIds?: string;
};

const T3Iframe = ({
  pr,
  co,
  flogin,
  fregister,
  loggedin,
  store_loyalty,
  yum_token
}: T3IFrameProps): JSX.Element => {
  const dispatch = useDispatch();
  const loyaltyToken = useSelector(selectors.loyaltyToken);
  const loyaltyCoupons = useSelector(selectors.getCoupons);
  const cart = useSelector(orderSelectors.cart);
  const urlParams = {
    pr,
    co,
    flogin,
    fregister,
    loggedin,
    store_loyalty,
    yum_token,
    sc_list: undefined
  };

  const iframeRef = useRef<HTMLIFrameElement>();
  const [iframeUrl, setIframeUrl] = useState(
    rewardsUrl(loyaltyToken, false, urlParams)
  );
  const [iframeHeight, setIframeHeight] = useState(1000);
  const { fetchCustomerDetails } = useCustomerDetails();

  const classes = useStyles();
  const analytics = useAnalytics();

  const { redeem, getInProgressLoyaltyCodes } = useRedeemLoyaltyRewards();

  const [{ enabled: refreshLoyaltyIframeEnabled }] = useDecision('fr-web-3716-refresh_loyalty_iframe');
  useEffect(() => {
    if (!refreshLoyaltyIframeEnabled) {
      return;
    }

    setIframeUrl(rewardsUrl(loyaltyToken, false, urlParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yum_token]);

  const viewRewardInCart = useCallback(() => {
    dispatch(openCartRail());
  }, [dispatch]);

  const handleLoyaltyToggle = (toggle: LoyaltyToggle) => {
    if (!iframeRef) return;

    if (toggle === LoyaltyState.OFF) {
      analytics.push(() => onHutRewardsOptOut());
      setIframeUrl(
        rewardsUrl(loyaltyToken, true, urlParams)
      );
    }

    if (toggle === LoyaltyState.ON) {
      setTimeout(() => {
        setIframeUrl(
          rewardsUrl(loyaltyToken, false, urlParams)
        );
      }, 500);
      iframeRef.current?.contentWindow?.postMessage(
        { new_user: loyaltyToken },
        '*'
      );
    }

    setTimeout(() => {
      fetchCustomerDetails();
    }, 500);
  };

  // Grab any in progress loyalty items, and provide to the iframe via query param
  useEffect(() => {
    if (cart && ((cart as YumCart).appliedPromotions || (cart as YumCart).warnings) && yum_token) {
      const loyaltyCodes = getInProgressLoyaltyCodes(cart);
      setIframeUrl(rewardsUrl(loyaltyToken, false, {
        ...urlParams,
        rewardIds: encodeURIComponent(loyaltyCodes.toString())
      }));
    } else if (loyaltyCoupons && !yum_token) {
      const rewardCodes = loyaltyCoupons.map((coupon) => coupon.rewardsRedemptionCode);
      standaloneApiClient.post({ loyaltyCoupons: rewardCodes }, StandaloneEndpoints.ENCRYPT_SC_LIST).then((res) => {
        setIframeUrl(rewardsUrl(loyaltyToken, false, {
          ...urlParams,
          sc_list: res?.scList
        }));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loyaltyToken, loyaltyCoupons, yum_token, cart]);

  useRewardsListener(iframeUrl, setIframeHeight, handleLoyaltyToggle, redeem, viewRewardInCart);

  return (
    <iframe
      ref={iframeRef as MutableRefObject<HTMLIFrameElement>}
      data-testid="rewards_iframe"
      src={String(iframeUrl)}
      className={classes.iframe}
      height={iframeHeight}
      title="Rewards"
    />
  );
};

export default T3Iframe;
